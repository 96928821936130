import * as React from "react";
import { io, Socket } from "socket.io-client";

export const useSocket = (
  url: string,
  token: string | null,
  onMessage: (message: Message) => void
) => {
  const [socket, setSocket] = React.useState<Socket | null>(null);

  React.useEffect(() => {
    if (token === null) {
      return;
    }

    setSocket(
      io(url, {
        query: { authType: "externalUser", token },
        withCredentials: true,
        transports: ["websocket", "polling"],
      })
    );

    return () => {
      if (socket !== null) {
        socket.close();
      }
    };
  }, [token, url]);

  React.useEffect(() => {
    socket?.on("onMessage", (msg: Message) => {
      onMessage(msg);
    });
  }, [socket, onMessage]);

  return socket;
};

export type Message = RecreditMessage | RecreditErrorMessage;
interface RecreditMessage {
  userId: number;
  type: "new_voucher";
  payload: {
    value: number;
  };
}
interface RecreditErrorMessage {
  userId: number;
  type: "recredit_error";
  payload: ServerErrorPayload | ServiceRecreditErrorPayload;
}
interface ServerErrorPayload {
  errorType: "server_error";
}
interface ServiceRecreditErrorPayload {
  errorType: "service_recredit_error";
  transactionId: number;
}
