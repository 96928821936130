import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";
import { DepositScreen } from "../screens/DepositScreen";
import HistoryScreen from "../screens/HistoryScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import { WelcomeScreen } from "../screens/WelcomeScreen";
import { RootStackParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

const GreenGoAppTitle = "GreenGo";

function RootNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="deposit"
        component={DepositScreen}
        options={{ title: GreenGoAppTitle }}
      />
      <Stack.Screen name="welcome" options={{ title: GreenGoAppTitle }}>
        {() => <WelcomeScreen canDisable={true} />}
      </Stack.Screen>
      <Stack.Screen name="help" options={{ title: GreenGoAppTitle }}>
        {() => <WelcomeScreen canDisable={false} />}
      </Stack.Screen>
      <Stack.Screen
        name="history"
        component={HistoryScreen}
        options={{ title: GreenGoAppTitle }}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: GreenGoAppTitle }}
      />
    </Stack.Navigator>
  );
}
