import active from "./active.env";

interface Env {
  GREENGO_API: string;
  WEBAPP_API: string;
  SOCKET_API: string;
}

const envs: Record<string, Env> = {
  local: {
    GREENGO_API: "http://localhost:3000",
    WEBAPP_API: "http://localhost:3010",
    SOCKET_API: "http://localhost:3020",
  },
  dev: {
    GREENGO_API: "https://api-dev.mygreengo.fr",
    WEBAPP_API: "https://webapp-api-dev.mygreengo.fr",
    SOCKET_API: "https://socket-dev.mygreengo.fr",
  },
  staging: {
    GREENGO_API: "https://api-staging.mygreengo.fr",
    WEBAPP_API: "https://webapp-api-staging.mygreengo.fr",
    SOCKET_API: "https://socket-staging.mygreengo.fr",
  },
  prod: {
    GREENGO_API: "https://api.mygreengo.fr",
    WEBAPP_API: "https://webapp-api.mygreengo.fr",
    SOCKET_API: "https://socket.mygreengo.fr",
  },
};

export default envs[active];
