import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { useContext, useEffect } from "react";
import { Image, Linking, StyleSheet, TouchableOpacity } from "react-native";
import { webAppApi } from "../api/webappApi";
import { LcgButton } from "../components/lcgComponents/LcgButton";
import { LcgCheckbox } from "../components/lcgComponents/LcgCheckbox";
import { LcgLabel } from "../components/lcgComponents/LcgLabel";
import { LcgBlue } from "../components/lcgComponents/variables";
import { Text, View } from "../components/Themed";
import { appInfoContext } from "../context/AppContext";
import { tokenContext } from "../context/TokenContext";
import { useToggle } from "../hooks/useToggle";
import { Colors } from "../constants/Colors";

export interface WelcomeScreenProps {
  canDisable: boolean;
}

export const WelcomeScreen: React.FunctionComponent<WelcomeScreenProps> = ({
  canDisable,
}) => {
  const navigation = useNavigation();

  useEffect(() => {
    document.title = "Bibak";
  });

  const [dontShowTutorialChecked, toggleDontShowTutorialChecked] = useToggle();
  const [cguChecked, toggleCguChecked] = useToggle();
  const token = React.useContext(tokenContext);

  const appInfo = useContext(appInfoContext);

  return (
    <View style={styles.container}>
      {appInfo?.backAppUrl && (
        <>
          <TouchableOpacity
            style={styles.closeAppContainer}
            onPress={() => {
              appInfo?.backAppUrl && Linking.openURL(appInfo.backAppUrl);
            }}
          >
            <Image
              style={styles.closeAppImage}
              source={require("../../assets/images/close.png")}
            />
          </TouchableOpacity>
        </>
      )}
      <View style={styles.containerHeader}>
        <Text style={styles.containerHeaderText}>Bienvenue !</Text>
      </View>
      <View style={styles.containerBottom}>
        <View style={styles.centerIcon}>
          <View style={styles.containerHeaderIcon}>
            <Image
              style={styles.centerIconImage}
              source={require("../../assets/images/bibak-background.png")}
            />
          </View>
        </View>
        <View style={styles.containerBottom}>
          <View style={styles.containerTopText}>
            <Text style={styles.topText}>
              Comment récupérer le montant de votre consigne ?
            </Text>
          </View>
          <View style={styles.containerTopIcons}>
            <View style={styles.containerTopIcon}>
              <Image
                style={styles.collectorImage}
                source={require("../../assets/images/collector.png")}
              ></Image>
              <Text style={styles.numberOneIcon}>1</Text>
              <View style={styles.textIconLeft}>
                <Text style={styles.textIcon}>Présentez vous devant</Text>
                <Text>
                  <Text style={styles.textIcon}>un </Text>
                  <Text style={styles.textIconBold}>collecteur </Text>
                  <Text style={styles.textIcon}>BIBAK</Text>
                </Text>
              </View>
            </View>
            <View style={styles.containerTopIcon}>
              <Image
                style={styles.scanContainerImage}
                source={require("../../assets/images/scan_container.png")}
              ></Image>
              <Text style={styles.numberTwoIcon}>2</Text>

              <View style={styles.textIconRight}>
                <Text>
                  <Text style={styles.textIcon}>Scannez le </Text>
                  <Text style={styles.textIconBold}>QR code </Text>
                </Text>
                <Text style={styles.textIcon}>présent sur l'emballage</Text>
                <Text style={styles.textIcon}>puis déposez-le</Text>
              </View>
            </View>
          </View>
          <View style={styles.containerBottomIcon}>
            <Image
              style={styles.smartphoneImage}
              source={require("../../assets/images/smartphone.png")}
            ></Image>
            <Text style={styles.numberThreeIcon}>3</Text>

            <View style={styles.textIconBottom}>
              <Text style={{ textAlign: "center" }}>
                <Text style={styles.textIcon}>Scannez </Text>
                <Text style={styles.textIconBold}>votre identifiant </Text>
                <Text style={styles.textIcon}>
                  pour récupérer le montant de votre consigne
                </Text>
              </Text>
            </View>
          </View>
        </View>
        {canDisable && (
          <>
            <LcgLabel>
              <LcgCheckbox
                isChecked={dontShowTutorialChecked}
                clickEvent={toggleDontShowTutorialChecked}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: Colors.black,
                    fontFamily: "InterMedium",
                  }}
                >
                  Ne plus afficher
                </Text>
              </LcgCheckbox>
            </LcgLabel>
            <LcgLabel header={true}>
              <LcgCheckbox isChecked={cguChecked} clickEvent={toggleCguChecked}>
                <Text
                  accessibilityRole="link"
                  style={{
                    color: Colors.black,
                    fontFamily: "InterBold",
                    textDecorationLine: "underline",
                    paddingRight: 10,
                  }}
                  onPress={() => {
                    window.open(
                      "https://www.bibak.fr/politique-de-confidentialite",
                      "_blank"
                    );
                  }}
                >
                  J’accepte les CGU
                </Text>
              </LcgCheckbox>
            </LcgLabel>
          </>
        )}

        <LcgButton
          onClick={async () => {
            if (dontShowTutorialChecked) {
              await webAppApi.setUserHasSeenTutorial(token!);
            }

            navigation.navigate("deposit");
          }}
          disabled={canDisable && !cguChecked}
        >
          Mon identifiant
        </LcgButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.secondary,
    minHeight: "100vh",
  },
  containerHeader: {
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    backgroundColor: Colors.secondary,
    top: 0,
  },
  containerHeaderText: {
    color: Colors.black,
    fontSize: 30,
    marginTop: -42,
    fontFamily: "Brule",
  },
  containerHeaderIcon: {
    position: "relative",
    backgroundColor: "transparent",
    top: -50,
  },
  centerIcon: {
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
  },
  centerIconImage: {
    width: 100,
    height: 100,
  },
  containerBottom: {
    backgroundColor: Colors.secondary,
    paddingBottom: 50,
  },
  containerTopText: {
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    top: -40,
  },
  topText: {
    color: Colors.black,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "InterBold",
    textAlign: "center",
  },
  containerTopIcons: {
    backgroundColor: Colors.secondary,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    width: "100%",
    maxWidth: "300px",
    margin: "auto",
  },
  containerTopIcon: {
    backgroundColor: "transparent",
    marginRight: 5,
    marginLeft: 5,
  },
  numberIconLeft: {
    position: "absolute",
    top: 95,
    left: 27,
  },
  textIconLeft: {
    backgroundColor: "transparent",
    alignItems: "center",
    marginTop: 15,
  },

  numberIconRight: {
    position: "absolute",
    top: 40,
    left: 45,
  },
  textIconRight: {
    backgroundColor: "transparent",
    alignItems: "center",
    marginTop: 15,
  },
  textIcon: {
    fontSize: 14,
    color: Colors.black,
    fontFamily: "InterRegular",
    lineHeight: 15,
  },
  textIconBold: {
    fontSize: 14,
    color: Colors.black,
    fontFamily: "InterRegular",
    fontWeight: "bold",
  },
  containerBottomIcon: {
    backgroundColor: Colors.secondary,
    position: "relative",
    alignItems: "center",
    height: 170,
  },
  containerPhoneIcon: {
    marginTop: 30,
    position: "absolute",
    backgroundColor: "transparent",
  },
  numberIconBottom: {
    position: "absolute",
    top: 65,
    right: 132,
  },
  textIconBottom: {
    backgroundColor: "transparent",
    top: 6,
    alignItems: "center",
    maxWidth: 280,
  },
  containerButton: {
    alignItems: "center",
    backgroundColor: Colors.secondary,
    height: 100,
    marginTop: 10,
  },
  button: {
    backgroundColor: "#fff",
    width: 220,
    height: 42,
    borderColor: "#FF9CFF",
    borderWidth: 4,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  textButton: {
    fontSize: 16,
    color: Colors.black,
    fontFamily: "InterRegular",
    fontWeight: "bold",
  },
  numberOneIcon: {
    marginTop: -35,
    marginLeft: 24,
    height: 45,
    width: 45,
    position: "absolute",
    right: 21,
    bottom: 36,
    backgroundColor: Colors.primary,
    color: Colors.black,
    borderRadius: 50,
    textAlign: "center",
    alignSelf: "center",
    fontFamily: "Brule",
    fontSize: 30,
  },
  numberTwoIcon: {
    marginTop: -22,
    marginLeft: 47,
    height: 45,
    width: 45,
    position: "absolute",
    right: 15,
    bottom: 45,
    backgroundColor: Colors.primary,
    color: Colors.black,
    borderRadius: 50,
    textAlign: "center",
    alignSelf: "center",
    fontFamily: "Brule",
    fontSize: 30,
  },
  numberThreeIcon: {
    marginTop: -31,
    marginLeft: 76,
    height: 45,
    width: 45,
    backgroundColor: Colors.primary,
    color: Colors.black,
    borderRadius: 50,
    textAlign: "center",
    alignSelf: "center",
    fontFamily: "Brule",
    fontSize: 30,
  },
  collectorImage: {
    height: 120,
    width: 120,
    marginLeft: "auto",
    marginRight: "auto",
  },
  scanContainerImage: {
    height: 100,
    width: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
  smartphoneImage: {
    height: 100,
    width: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
  closeAppContainer: {
    position: "absolute",
    left: 0,
    alignSelf: "flex-start",
    zIndex: 666,
  },
  closeAppImage: {
    height: 60,
    width: 60,
    tintColor: Colors.black,
  },
});
