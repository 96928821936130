import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { LcgBlue, LcgGreen } from "./variables";
import { Colors } from "../../constants/Colors";

interface LcgButtonProps {
  onClick: () => void;
  size?: "fit" | "large";
  disabled?: boolean;
  children?: string;
}

export const LcgButton: React.FunctionComponent<LcgButtonProps> = ({
  onClick,
  children,
  disabled = false,
  size = "fit",
}) => {
  return (
    <View
      style={size === "large" ? styles.largeContainer : styles.fitContainer}
    >
      <TouchableOpacity
        style={disabled ? styles.faded : null}
        onPress={onClick}
        disabled={disabled}
      >
        <View style={styles.button}>
          <Text style={styles.textButton}>{children}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  fitContainer: { alignSelf: "center" },
  largeContainer: {
    alignSelf: "stretch",
  },
  faded: {
    opacity: 0.5,
    backgroundColor: LcgGreen,
    borderRadius: 15,
  },
  button: {
    backgroundColor: Colors.black,
    paddingVertical: 15,
    paddingHorizontal: 35,
    borderRadius: 15,
  },
  textButton: {
    fontSize: 16,
    color: Colors.white,
    fontWeight: "bold",
    textAlign: "center",
  },
});
