import { DateTime } from "luxon";
import * as React from "react";
import { FlatList, Image, StyleSheet } from "react-native";
import NavHeader from "../components/NavHeader";
import { Text, View } from "../components/Themed";
import { userDataContext } from "./../context/UserDataContext";
import {
  mergeAllTransactions,
  Transaction,
} from "./../helpers/transactionHelper";
import { Colors } from "../constants/Colors";

export default function HistoryScreen({ navigation }: any) {
  const renderRow = (item: Transaction) => {
    switch (item.in_or_out) {
      case "out":
        return (
          <>
            <View style={styles.containerTransaction}>
              <View style={styles.leftInformationContainer}>
                <Text style={styles.dateText}>
                  {new Date(item.createdAt).toLocaleString()}
                </Text>
                <Text style={styles.sourceText}>Utilisé en bon d'achat</Text>
              </View>
              <Text style={styles.valueText}>-{item.value.toFixed(2)} €</Text>
            </View>
            <View style={styles.separator} />
          </>
        );
      case "in":
        return (
          <>
            <View style={styles.containerTransaction}>
              <View style={styles.leftInformationContainer}>
                <Text style={styles.dateText}>
                  {new Date(item.createdAt).toLocaleString()}
                </Text>
                <Text style={styles.sourceText}>
                  {item.value === 0
                    ? "Levée de caution"
                    : `Dépôt de ${item.nbOfContents} emballage${
                        item.nbOfContents > 1 ? "s" : ""
                      }`}
                </Text>
              </View>
              <Text style={styles.valueText}>+{item.value.toFixed(2)} €</Text>
            </View>
            <View style={styles.separator} />
          </>
        );
    }
  };

  return (
    <userDataContext.Consumer>
      {(user) => {
        return (
          <View style={styles.container}>
            <NavHeader
              navigation={navigation}
              title="Historique"
              isLight={false}
            />
            {user && (
              <View style={styles.containerItems}>
                {mergeAllTransactions(
                  // @ts-ignore
                  user.transactionIns,
                  user.transactionOuts
                ).map((item) => renderRow(item))}
              </View>
            )}
          </View>
        );
      }}
    </userDataContext.Consumer>
  );
}

// <FlatList
//   data={mergeAllTransactions(
//     user.transactionIns,
//     user.transactionOuts
//   )}
//   renderItem={({ item }) => renderRow(item)}
// />

const styles = StyleSheet.create({
  container: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: "20%",
  },
  containerItems: {
    backgroundColor: Colors.white,
    width: "90%",
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,

    elevation: 2,
  },
  containerTransaction: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.white,
  },
  leftInformationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: Colors.white,
    marginVertical: 15,
  },
  dateText: {
    fontFamily: "InterBold",
    fontSize: 15,
    letterSpacing: 0.75,
    color: Colors.black,
  },
  sourceText: {
    fontFamily: "InterRegular",
    fontSize: 12,
    color: Colors.black,
  },
  valueText: {
    fontFamily: "InterBold",
    fontSize: 15,
    color: Colors.black,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(51, 51, 51, 0.15)",
  },
});
