import { useEffect, useState } from "react";
import { AppInfo } from "./../models/appInfo";

export const useUrlInfo = () => {
  const [token, setToken] = useState<string | null>(null);
  const [appInfo, setAppInfo] = useState<AppInfo | null>(null);

  useEffect(() => {
    try {
      if (
        window.location.hash !== null &&
        window.location.hash !== undefined &&
        window.location.hash !== ""
      ) {
        const query = new URLSearchParams(
          window.location.hash.replace("#", "")
        );
        window.location.hash = ""; //we remove this value to avoid XXS attacks if the user go to an external link
                
        setToken(query.get("userInfo"));
        setAppInfo({
          backAppUrl: query.get("app") ?? undefined,
          sessionCookieName: query.get("sessionCookieName") ?? undefined,
          sessionCookieDomain: query.get("sessionCookieDomain") ?? undefined,
        });
      }
    } catch (e) {
      console.exception(e);
    }
  });
  return [token, appInfo] as const;
};
