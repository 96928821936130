import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, Text } from "react-native";
import { AideButton } from "../components/AideButton";
import GreengoBarcodeView from "../components/GreengoBarcodeView";
import NavHeader from "../components/NavHeader";
import { View } from "../components/Themed";
import { userDataContext } from "./../context/UserDataContext";
import { Colors } from "../constants/Colors";

export const DepositScreen: React.FunctionComponent = () => {
  const userInfo = React.useContext(userDataContext);
  const navigation = useNavigation();

  // React.useEffect(() => {
  //   if (userInfo !== null && !userInfo.hasSeenTutorial) {
  //     navigation.navigate("welcome");
  //   }
  // }, [userInfo?.hasSeenTutorial, navigation]);

  return (
    <View style={styles.container}>
      <NavHeader
        navigation={navigation}
        title="Mon identifiant"
        isLight={false}
      ></NavHeader>
      <View style={{ flex: 1, flexDirection: "column", width: "100%" }}>
        {userInfo && (
          <GreengoBarcodeView
            barcode={userInfo.clientNumber}
          ></GreengoBarcodeView>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  aideContainer: {
    backgroundColor: "rgba(0,0,0,0)",
    position: "absolute",
    top: 0,
    alignSelf: "flex-end",
  },
});
