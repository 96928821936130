import React, { useCallback, useState } from "react";
import { Message, useSocket } from "./useSocket";

export const useSocketMessages = (url: string, token: string | null) => {
  const [messages, setUnreadMessage] = useState<Message[]>([]);
  const onMessage = React.useCallback(
    (message: Message) => {
      setUnreadMessage([message, ...messages]);
    },
    [messages, setUnreadMessage]
  );

  const processUnreadMessages = useCallback(() => {
    setUnreadMessage(messages.filter((v, index, arr) => index !== 0));
  }, [setUnreadMessage, messages]);

  useSocket(url, token, onMessage);

  return [messages, processUnreadMessages] as const;
};
