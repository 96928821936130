import React, { useContext, useState } from "react";
import { Image, Linking, StyleSheet, TouchableOpacity } from "react-native";
import { appInfoContext } from "../context/AppContext";
import { deleteCookie } from "../utils/cookie";
import { userDataContext } from "./../context/UserDataContext";
import { Text, View } from "./Themed";
import { Colors } from "../constants/Colors";

export default function NavHeader({ navigation, title, isLight }: any) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const appInfo = useContext(appInfoContext);
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {appInfo?.backAppUrl && (
          <>
            <TouchableOpacity
              style={styles.closeAppContainer}
              onPress={() => {
                appInfo?.backAppUrl && Linking.openURL(appInfo.backAppUrl);
              }}
            >
              <Image
                style={styles.closeAppImage}
                source={require("../../assets/images/close.png")}
              />
            </TouchableOpacity>
          </>
        )}

        <Text style={isLight ? styles.titleLight : styles.titleDark}>
          {title}
        </Text>
        <TouchableOpacity
          onPress={() => {
            setIsMenuVisible(true);
          }}
        >
          <View style={styles.containerBurger}>
            <Image
              style={styles.burgerImage}
              source={require("../../assets/images/burger.png")}
            ></Image>
          </View>
        </TouchableOpacity>
      </View>

      {isMenuVisible && (
        <View style={styles.containerMenu}>
          <TouchableOpacity
            onPress={() => {
              setIsMenuVisible(false);
            }}
          >
            <View style={styles.sectionBackGround}>
              <Image
                style={styles.closeImage}
                source={require("../../assets/images/close.png")}
              ></Image>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setIsMenuVisible(false);
              navigation.navigate("deposit");
            }}
          >
            <View style={styles.sectionBackGround}>
              <Text style={styles.textSection}>Dépôt</Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setIsMenuVisible(false);
              navigation.navigate("history");
            }}
          >
            <View style={styles.sectionBackGround}>
              <Text style={styles.textSection}>Mes transactions</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL("mailto:support@bibak.fr");
            }}
          >
            <View style={styles.sectionBackGround}>
              <Text style={styles.textContact}>Contactez-nous</Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    position: "absolute",
    top: 0,
    width: "100vw",
    backgroundColor: "transparent",
    zIndex: 15,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "transparent",
  },
  header: {
    width: "100%",
    backgroundColor: "transparent",
    alignItems: "flex-start",
  },
  titleLight: {
    fontSize: 30,
    marginTop: 14,
    color: Colors.white,
    fontFamily: "Brule",
    paddingLeft: 20,
  },
  titleDark: {
    fontSize: 30,
    marginTop: 14,
    color: Colors.black,
    fontFamily: "Brule",
    paddingLeft: 20,
  },
  containerBurger: {
    marginTop: -45,
    backgroundColor: "transparent",
    alignItems: "flex-end",
    width: "100vw",
  },
  burgerImage: {
    height: 63,
    width: 63,
    tintColor: Colors.black,
  },
  toggleMenu: {
    backgroundColor: Colors.white,
  },
  overlay: {
    width: "80%",
    backgroundColor: Colors.white,
    opacity: 70,
  },
  modal: {
    backgroundColor: Colors.white,
    opacity: 70,
    maxHeight: "50vh",
  },
  text: {
    color: "#3F2949",
    marginTop: 10,
  },
  containerMenu: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 211,
    backgroundColor: "#FFFFFF",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 8,
    elevation: 7,
  },
  sectionBackGround: {
    backgroundColor: "transparent",
  },
  textSection: {
    color: Colors.black,
    fontSize: 18,
    fontWeight: "600",
    marginLeft: 30,
    marginBottom: 10,
    marginTop: 10,
    marginRight: 10,
    fontFamily: "InterMedium",
  },
  textContact: {
    color: Colors.black,
    fontFamily: "InterMedium",
    fontSize: 18,
    fontWeight: "600",
    marginLeft: 25,
    marginBottom: 10,
    marginTop: 10,
  },
  containerLine: {
    backgroundColor: "transparent",
  },
  bottomLineSection: {
    width: "73%",
    borderBottomWidth: 1,
    borderBottomColor: Colors.black,
    alignItems: "center",
    marginLeft: 20,
  },
  closeImage: {
    height: 60,
    width: 60,
    marginTop: 10,
    alignSelf: "flex-end",
    tintColor: Colors.black,
  },
  closeAppContainer: {
    position: "absolute",
    alignSelf: "flex-start",
    zIndex: 666,
    tintColor: Colors.black,
  },
  closeAppImage: {
    height: 60,
    width: 60,
  },
  smallImage: {
    height: 20,
    width: 25,
    marginLeft: 10,
  },
  listItemPrice: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#FF66FF",
    display: "flex",
    height: 20,
    borderRadius: 25,
    marginTop: 13,
    paddingLeft: 15,
    paddingRight: 15,
  },
});
