import * as React from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
const checkedImage = require("../../../assets/images/check.png");
const uncheckedImage = require("../../../assets/images/uncheck.png");

interface LcgCheckboxProps {
  isChecked: boolean;
  clickEvent: () => void;
  children: React.ReactNode;
}

export const LcgCheckbox: React.FunctionComponent<LcgCheckboxProps> = ({
  isChecked,
  clickEvent,
  children,
}) => {
  return (
    <>
      <View style={styles.container}>
        <View style={styles.text}>{children}</View>
        <TouchableOpacity onPress={clickEvent}>
          <View>
            <Image
              style={styles.image}
              source={isChecked === true ? checkedImage : uncheckedImage}
            />
          </View>
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  text: {},
  image: {
    marginLeft: 15,
    alignItems: "flex-end",
    width: 50,
    height: 50,
  },
});
