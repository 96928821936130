import * as React from "react";
import { Text as DefaultText, View as DefaultView } from "react-native";

import { Colors } from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText["props"];
export type ViewProps = ThemeProps & DefaultView["props"];

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;

  return (
    <DefaultText style={[{ color: Colors.black }, style]} {...otherProps} />
  );
}

export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;

  return (
    <DefaultView
      style={[{ backgroundColor: Colors.secondary }, style]}
      {...otherProps}
    />
  );
}
