import { DateTime } from "luxon";
import { TransactionIn, TransactionOut } from "./../models/UserInfo";

export type Transaction = TransactionIn | TransactionOut;

export const mergeAllTransactions = (
  transactionIn: TransactionIn[],
  transactionOut: TransactionOut[]
): Transaction[] => {
  try {
    const mergedTransaction = (transactionIn as Transaction[])
      .concat(transactionOut)
      .sort(compareTwoTransactionsDesc);
    return mergedTransaction;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getNewTransactions = (
  previousTransactions: TransactionIn[],
  newTransactions: TransactionIn[]
): TransactionIn[] => {
  if (
    previousTransactions.length === newTransactions.length ||
    newTransactions.length === 0
  ) {
    return [];
  }

  const sortedTransactions = newTransactions.sort(compareTwoTransactionsDesc);
  return sortedTransactions.slice(
    0,
    newTransactions.length - previousTransactions.length
  );
};

const compareTwoTransactionsDesc = (t1: Transaction, t2: Transaction) => {
  const t1Date = DateTime.fromISO(t1.createdAt);
  const t2Date = DateTime.fromISO(t2.createdAt);
  if (t1Date > t2Date || (+t1Date === +t2Date && t1.in_or_out === "out")) {
    return -1;
  } else if (t1Date < t2Date) {
    return 1;
  }
  return 0;
};
