import * as React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { LcgButton } from "./LcgButton";
import { Colors } from "../../constants/Colors";

interface LcgPopupProps {
  title: string;
  picture?: string;
  children?: React.ReactChild;
  buttonText: string;
  onClose?: () => void;
  onValidate?: () => void;
}

export const LcgPopup: React.FunctionComponent<LcgPopupProps> = ({
  title,
  picture,
  children,
  buttonText,
  onClose,
  onValidate,
}) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.popUpFade}
        onPress={onClose}
      ></TouchableOpacity>

      <View style={styles.popUpContainer}>
        {onClose && (
          <TouchableOpacity
            style={styles.closeImageContainer}
            onPress={onClose}
          >
            <Image
              style={styles.closeImage}
              source={require("../../../assets/images/close.png")}
            ></Image>
          </TouchableOpacity>
        )}
        {picture && (
          <Image
            style={styles.mainImage}
            source={require("../../../assets/images/" + picture + ".png")}
          ></Image>
        )}
        <Text style={styles.textTitle}>{title}</Text>
        {children}
        {onValidate && (
          <LcgButton size="large" onClick={onValidate}>
            {buttonText}
          </LcgButton>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    width: "100vw",
    height: "100%",
    zIndex: 20,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
  popUpFade: {
    position: "absolute",
    top: 0,
    width: "100vw",
    height: "100%",
    backgroundColor: "rgba(212, 255, 235, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popUpContainer: {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    width: "90vw",
    borderRadius: 10,
    borderWidth: 5,
    borderColor: Colors.primary,
    backgroundColor: Colors.primary,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  closeImageContainer: {
    position: "absolute",
    height: 40,
    width: 60,
    top: 10,
    right: 10,
    borderRadius: 50,
    backgroundColor: Colors.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeImage: {
    height: 45,
    width: 45,
    tintColor: Colors.white,
  },
  mainImage: {
    height: 80,
    width: 93,
    marginTop: 15,
    marginBottom: 15,
  },
  textTitle: {
    fontSize: 20,
    color: Colors.black,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 15,
    marginBottom: 15,
    fontFamily: "InterBold",
  },
});
