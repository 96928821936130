import * as React from "react";
import { StyleSheet, Text } from "react-native";
import { LcgBlue } from "./variables";

interface LcgBlueTextProps {
  children?: React.ReactNode | undefined;
}

export const LcgBlueText: React.FunctionComponent<LcgBlueTextProps> = ({
  children,
}) => {
  return <Text style={styles.text}>{children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    color: LcgBlue,
    fontWeight: "normal",
    marginVertical: 10,
  },
});
