import { StackScreenProps } from "@react-navigation/stack";
import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { RootStackParamList } from "../types";
import { Colors } from "../constants/Colors";
import { LcgButton } from "../components/lcgComponents/LcgButton";

export default function NotFoundScreen({
  navigation,
}: StackScreenProps<RootStackParamList, "NotFound">) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>404</Text>
      <Text style={styles.subtitle}>{"Oups !\nCette page n'éxiste pas"}</Text>
      <LcgButton onClick={() => navigation.navigate("welcome")}>
        Retourner à l'accueil
      </LcgButton>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.secondary,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 70,
    fontFamily: "Brule",
    color: Colors.black,
  },
  subtitle: {
    fontSize: 15,
    fontFamily: "InterBold",
    color: Colors.black,
    textAlign: "center",
    marginBottom: "15%",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    fontFamily: "InterBold",
    color: Colors.black,
    textDecorationStyle: "solid",
    textDecorationLine: "underline",
  },
});
