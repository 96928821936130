import { useCallback, useEffect, useState } from "react";
import { webAppApi } from "../api/webappApi";
import { UserInfo } from "../models/UserInfo";

export const useUserInfo = (token: string | null) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    getUserInfo(token);
  }, [token]);

  const getUserInfo = useCallback(
    async (token: string | null) => {
      if (token === null) {
        setUserInfo(null);
        return;
      }
      const newUserInfo = await webAppApi.getUserInfo(token);
      const newUserInfoWithImpact =
        newUserInfo === null
          ? null
          : {
              ...newUserInfo,
              impact: newUserInfo.transactionIns.reduce(
                (prev, t) => prev + t.nbOfContents,
                0
              ),
            };

      setUserInfo(newUserInfoWithImpact);
    },
    [token, setUserInfo, userInfo]
  );

  const refreshUser = useCallback(() => getUserInfo(token), [
    getUserInfo,
    token,
  ]);

  return [userInfo, refreshUser] as const;
};
