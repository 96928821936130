import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "./Themed";
import { Colors } from "../constants/Colors";
import SvgQRCode from "react-native-qrcode-svg";

export default function GreengoBarcodeView({ barcode }: { barcode: string }) {
  return (
    <View style={styles.mainContainer}>
      <SvgQRCode value={barcode} size={150} color={Colors.black} />
      <Text style={styles.text}>{barcode}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: Colors.white,
    zIndex: 99,
    marginHorizontal: "10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    paddingVertical: 40,
    borderRadius: 10,
    marginTop: "25%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,

    elevation: 2,
  },
  text: {
    marginTop: 40,
    fontSize: 15,
    fontFamily: "InterBold",
    letterSpacing: 0.75,
    color: Colors.black,
  },
});
