import * as React from "react";
import { Text, View } from "react-native";
import { LcgBlueText } from "./LcgBlueText";
import { LcgPopup } from "./LcgPopup";
import { Colors } from "../../constants/Colors";

interface RecreditPopupProps {
  value: number;
  close: () => void;
}

export const RecreditPopup: React.FunctionComponent<RecreditPopupProps> = ({
  value,
  close,
}) => {
  return (
    <LcgPopup
      title="Merci !"
      picture="heart"
      onValidate={close}
      onClose={close}
      buttonText="OK"
    >
      <LcgBlueText>
        <View style={{ flex: 1, flexDirection: "column" }}>
          <Text
            style={{
              textAlign: "center",
              color: Colors.black,
              fontFamily: "InterMedium",
              marginBottom: "15%",
            }}
          >
            Vous avez été crédité de {value}€
          </Text>
        </View>
      </LcgBlueText>
    </LcgPopup>
  );
};
