import * as Font from "expo-font";
import { StatusBar } from "expo-status-bar";
import React, { useCallback } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { PopupHandler } from "./src/components/PopupHandler";
import { AppInfoProvider } from "./src/context/AppContext";
import { TokenProvider } from "./src/context/TokenContext";
import { UserDataProvider } from "./src/context/UserDataContext";
import env from "./src/environment/env";
import useCachedResources from "./src/hooks/useCachedResources";
import useColorScheme from "./src/hooks/useColorScheme";
import { useSocketMessages } from "./src/hooks/useSocketMessage";
import { useUrlInfo } from "./src/hooks/useUrlInfo";
import { useUserInfo } from "./src/hooks/useUserInfo";
import Navigation from "./src/navigation";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const [token, appInfo] = useUrlInfo();

  const [userInfo, refreshUserInfo] = useUserInfo(token);

  const [loaded] = Font.useFonts({
    Brule: require("./assets/fonts/Brule-Bold.otf"),
    InterRegular: require("./assets/fonts/Inter-Regular.ttf"),
    InterBold: require("./assets/fonts/Inter-Bold.ttf"),
    InterMedium: require("./assets/fonts/Inter-Medium.ttf"),
    InterSemiBold: require("./assets/fonts/Inter-SemiBold.ttf"),
  });

  const [messages, processUnreadMessages] = useSocketMessages(
    env.SOCKET_API,
    token
  );
  const processMessage = useCallback(() => {
    processUnreadMessages();
    refreshUserInfo();
  }, [processUnreadMessages, refreshUserInfo]);

  return (
    isLoadingComplete && (
      <>
        <PopupHandler messages={messages} consumeMessage={processMessage} />
        <TokenProvider value={token}>
          <AppInfoProvider value={appInfo}>
            <UserDataProvider value={userInfo}>
              <SafeAreaProvider>
                <Navigation colorScheme={colorScheme} />
                <StatusBar />
              </SafeAreaProvider>
            </UserDataProvider>
          </AppInfoProvider>
        </TokenProvider>
      </>
    )
  );
}
