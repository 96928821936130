import * as React from "react";
import { Text, View, StyleSheet } from "react-native";
import { LcgBlueText } from "./LcgBlueText";
import { LcgPopup } from "./LcgPopup";
import { Colors } from "../../constants/Colors";

interface ErrorRecreditPopup {
  onSignal: () => void;
}

export const ErrorRecreditPopup: React.FunctionComponent<
  ErrorRecreditPopup
> = ({ onSignal }) => {
  return (
    <LcgPopup title="Désolé !" onValidate={onSignal} buttonText="Signaler">
      <View>
        <Text style={styles.text}>Votre compte n'a pas pu être crédité...</Text>
        <Text style={styles.text}>
          Signalez le à support@bibak.fr pour récupérer le montant de votre
          consigne
        </Text>
      </View>
    </LcgPopup>
  );
};

const styles = StyleSheet.create({
  text: {
    color: Colors.black,
    fontFamily: "InterMedium",
    textAlign: "center",
    marginVertical: "5%",
  },
});
