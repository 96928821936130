import React from "react";
import { Linking } from "react-native";
import { Message } from "../hooks/useSocket";
import { ErrorRecreditPopup } from "./lcgComponents/ErrorRecreditPopup";
import { RecreditPopup } from "./lcgComponents/RecreditPopup";

interface PopupHandlerProps {
  messages: Message[];
  consumeMessage: () => void;
}

export const PopupHandler: React.FunctionComponent<PopupHandlerProps> = ({
  messages,
  consumeMessage,
}) => {
  const message = messages[0];
  return (
    <>
      {message?.type === "recredit_error" ? (
        <ErrorRecreditPopup
          onSignal={() => {
            var transaction =
              message.payload.errorType === "service_recredit_error"
                ? `-${message.payload.transactionId}`
                : "";
            Linking.openURL(
              `mailto:support@bibak.fr?subject=Echec%20retour%20emballage-${message.userId}${transaction}`
            );
            consumeMessage();
          }}
        />
      ) : message?.type === "new_voucher" ? (
        <RecreditPopup value={message.payload.value} close={consumeMessage} />
      ) : null}
    </>
  );
};
