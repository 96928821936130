import * as Linking from "expo-linking";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Deposit: {
            screens: {
              DepositScreen: "one",
            },
          },
          History: {
            screens: {
              HistoryScreen: "two",
            },
          },
        },
      },
      Deposit: {
        screens: {
          Deposit: {
            screens: {
              DepositScreen: "deposit",
            },
          },
        },
      },
      History: {
        screens: {
          History: {
            screens: {
              HistoryScreen: "history",
            },
          },
        },
      },
      Welcome: {
        screens: {
          Welcome: {
            screens: {
              WelcomeScreen: "welcome",
            },
          },
        },
      },
      Help: {
        screens: {
          Help: {
            screens: {
              HelpScreen: "help",
            },
          },
        },
      },
      NotFound: "*",
    },
  },
};
